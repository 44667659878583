import { PropsWithChildren, useContext } from "react";
import { useUserShowSelf } from "@gocardless/api/dashboard/user";
import { IdentityCheckWrapper } from "src/components/authentication/identityCheck/IdentityCheckWrapper";
import { Box } from "@gocardless/flux-react";
import { useOptimizelyVariation } from "src/components/third-parties/Optimizely/useOptimizelyVariation"; // Import the hook
import { OptimizelyFlag } from "src/components/third-parties/Optimizely/constants"; // Import the feature flag
import { useGetPrimaryCreditorResponse } from "src/queries/creditor";
import { FullStory } from "src/components/third-parties/FullStory";
import { UserLocaleWrapper } from "src/components/i18n";
import { useAccessToken } from "src/common/authorisation";
import { Intercom, Zendesk, Drift } from "src/components/third-parties";
import { Optimizely } from "src/components/third-parties/Optimizely";
import { GlobalState } from "src/components/global-state/GlobalStateProvider";
import { useTermsAccepted } from "src/components/authentication/termsAccepted/useTermsAccepted";

import { EnforceMFAWrapper } from "./mfa/EnforceMFAWrapper";
import Banners from "./Banners";
import { RedirectFromMFAWrapper } from "./mfa/RedirectFromMFAWrapper";

interface Props {
  enforceMFA?: boolean;
  enforceIdentityCheck?: boolean;
  withZendesk?: boolean;
  children: React.ReactNode;
}

const AuthSync: React.FC<Props> = ({
  enforceMFA,
  enforceIdentityCheck,
  children,
}) => {
  useAccessToken();
  useTermsAccepted();
  const { data } = useUserShowSelf();
  const { bannersHeight } = useContext(GlobalState);

  // Use the Optimizely hook to get the feature flag value

  if (!data) {
    return null;
  }

  return (
    <RedirectFromMFAWrapper>
      <EnforceMFAWrapper enforceMFA={enforceMFA}>
        <Optimizely>
          <FullStory withOptimizely />
          <IdentityCheckWrapper enforceIdentityCheck={enforceIdentityCheck}>
            <Banners />
            <UserLocaleWrapper>
              <Box css={{ paddingTop: `${bannersHeight}px` }}>
                {/* Render the determined SupportComponent */}
                <SupportComponent>{children}</SupportComponent>
              </Box>
            </UserLocaleWrapper>
          </IdentityCheckWrapper>
        </Optimizely>
      </EnforceMFAWrapper>
    </RedirectFromMFAWrapper>
  );
};

export const SupportComponent: React.FC<PropsWithChildren> = ({ children }) => {
  <>{children}</>;

  const { isVariationOn, clientReady } = useOptimizelyVariation({
    flag: OptimizelyFlag.BI_ZENDESK_MIGRATION,
  });
  const { data: creditorResponse, isLoading } = useGetPrimaryCreditorResponse();
  if (!clientReady || isLoading) {
    return <>{children}</>;
  }

  if (creditorResponse?.creditors?.activated) {
    return <Drift>{children}</Drift>;
  }

  if (!isVariationOn) {
    return <Intercom>{children}</Intercom>;
  }

  return <Zendesk creditor={creditorResponse?.creditors}>{children}</Zendesk>;
};

export default AuthSync;
